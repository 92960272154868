<template lang="">
    <div>
        <Card>
            <template #header>Banka Açıklamaları</template>
            <div class="form-group">
                <label for="beyan">Banka Beyan Kodu</label>
                <input type="text" id="beyan" class="form-control">
            </div>
            <div class="form-group">
                <label for="no">Emlak No (İş Birimi)</label>
                <input type="text" id="no" class="form-control">
            </div>
            <div class="form-group">
                <label for="aciklama">Banka Açıklama</label>
                <textarea name="" id="aciklama"  rows="4" class="form-control"></textarea>
            </div>
            <div class="form-group d-flex">
                <button class="btn btn-success ml-auto">Kaydet</button>
            </div>
        </Card>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>